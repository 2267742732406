.calendarContainer :global(.fc-toolbar.fc-header-toolbar) {
  margin-bottom: 29px;
}

.calendarContainer :global(.fc-button-primary) {
  height: 41px;

  border: 1px solid #e8d17e;
  border-radius: 6px;

  background: transparent;
}
.time-selector{
    width: 100%;
    height: 50px;
    padding: 0 16px;
    border: 0;
    border-radius: 6px;
    background: rgba(217, 217, 217, 0.65);
}
.calendarContainer :global(.fc-button-primary):hover,
.calendarContainer :global(.fc-button-primary):disabled {
  border: 1px solid #e8d17e;

  background: rgba(232, 209, 126, 0.25);
}

.calendarContainer :global(.fc-button-primary):not(:disabled):active {
  background: rgba(232, 209, 126, 0.5);
}

.calendarContainer :global(.fc-scrollgrid) {
  border: none;
  border-radius: 8px;

  background: #252525;

  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
}

.calendarContainer :global(.fc-timegrid-axis) {
  border-right: none;
  border-bottom-color: #555;
}

.calendarContainer :global(.fc-col-header-cell) {
  border-left: none;
  border-right: none;
  border-bottom-color: #555;
}

.calendarContainer :global(.fc-col-header-cell-cushion) {
  margin: 5px 0;

  color: #fff;
  font-weight: 400;
  text-decoration: none;
}

.calendarContainer :global(.fc-scrollgrid-section-header) > th {
  border-right: none;
}

.calendarContainer :global(.fc-scrollgrid-section-body) > td {
  border: none;
}

.calendarContainer :global(.fc-daygrid-day) {
  border-color: #555;
}

.calendarContainer :global(.fc-timegrid-divider) {
  padding: 0;

  border: none;
}

.calendarContainer :global(.fc-timegrid-slot) {
  border-color: #555;
}

.calendarContainer :global(.fc-timegrid-slot-minor) {
  border-top: none;
}

.calendarContainer :global(.fc-timegrid-col) {
  border-color: #555;
}

.calendarContainer :global(.fc-timegrid-event-harness) {
  margin: 0 4px 0 6px;
}

.calendarContainer :global(.fc-timegrid-event) {
  box-shadow: 0 0 0 1px #000;
}

.repeat {
  margin-left: 32px;
}

.repeat .days {
  display: flex;
  flex-wrap: wrap;
}

.controlButtons {
  display: flex;

  justify-content: space-between;
}
