.labelAndInput {
  width: 100%;

  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
}

.labelAndInput.inline {
  width: 106px;

  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.label {
  padding-bottom: 5px;

  color: #e8d17e;
}

.labelAndInput.inline .label {
  padding-bottom: 0;
  margin-left: 5px;
}

.input[type="text"],
.input[type="time"],
.input[type="password"],
.input[type="date"] {
  width: 100%;
  height: 50px;

  padding: 0 16px;
  border: 0;
  border-radius: 6px;

  background: rgba(217, 217, 217, 0.65);
}

.input[type="text"]::placeholder,
.input[type="password"]::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
}

.input[type="date"] {
  cursor: text;
}

.checkbox {
  display: inline-flex;
  align-items: center;

  position: relative;

  cursor: pointer;
}

.checkbox > span {
  padding-left: 0.25rem;
}

.checkbox > .input {
  height: 18px;
  width: 18px;

  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  border: none;
  border-radius: 4px;
  outline: none;

  background: rgba(217, 217, 217, 0.65);

  cursor: pointer;
}

.checkbox > .input:checked {
  background: #e8d17e;
}

.checkbox > .input:checked + span::before {
  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg height='14px' width='14px' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 17.837 17.837' xml:space='preserve'%3E%3Cg%3E%3Cpath style='fill:%23000;' d='M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z'/%3E%3C/g%3E%3C/svg%3E");

  position: absolute;
  left: 0.15rem;
  top: 0.1rem;
}
