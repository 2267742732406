.table {
  width: 100%;
  
  background: #252525;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.table thead > tr,
.table tbody > tr:not(:last-of-type) {
  border-bottom: 1px solid #555;
}

.table thead > tr > th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table thead > tr > th:first-of-type,
.table tbody > tr > td:first-of-type {
  padding-left: 15px;
}

.table thead > tr > th:last-of-type,
.table tbody > tr > td:last-of-type {
  padding-right: 15px;
}

.table tbody > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;

  vertical-align:top;
}
