.sideNav {
  width: 307px;
  min-height: 100vh;

  padding: 51px 61px 0 61px;

  background: radial-gradient(
    46.34% 158px at 46.58% 84px,
    #303030 0%,
    #000000 100%
  );
}

.logo {
  align-self: center;
  margin-bottom: 84px;
}
