:global(.nav-pills .nav-link).navLink {
  padding: 3px 0 4px 10px;
  margin-bottom: 10px;

  border: 1px solid transparent;
  border-radius: 6px;

  display: flex;
  align-items: center;

  color: #fff;
}

:global(.nav-pills .nav-link).navLink:hover {
  background: rgba(232, 209, 126, 0.25);
}

:global(.nav-pills .nav-link.active).navLink {
  border-color: #e8d17e;
  
  color: #e8d17e;

  background: transparent;
}

:global(.nav-pills .nav-link.active).navLink svg.navLinkIcon path {
  fill: #e8d17e;
}

.navLink .navLinkText {
  margin-left: 12px;

  font-weight: 300;
}
