.loginForm {
  height: 100vh;

  display: flex;
  justify-content: center;

  background: #000;
}

.innerForm {
  width: 370px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-bottom: 77px;
}
