.username {
  text-decoration: underline;

  cursor: pointer;
}

.username:hover {
  color: #ccc;
}

:global(.modal-dialog).modal {
  max-width: 60%;
}

.modal :global(.modal-content) {
  background: #252525;
}

.modal :global(.modal-header) {
  border-bottom: none;
}

.modal :global(.btn-close) {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
}

:global(.modal-body).modalBody {
  padding: 0 0 42px 42px;

  display: flex;
}

.userInfo {
  padding-right: 20px;

  display: flex;
  flex-direction: column;
}

.userInfoHeader {
  margin-bottom: 14px;

  font-size: 20px;
  font-weight: 600;
}

.memberSince {
  margin-top: 6px;
}

.email {
  margin-top: 21px;
}

.phone {
  margin-top: 10px;
}

.profileContainer {
  width: 40%;

  padding: 0 23px;

  border-left: 1px solid #555;

  display: flex;
  flex-direction: column;
}

.profileHeader {
  margin-bottom: 23px;

  font-weight: 700;
}

.attributes {
  margin-bottom: 38px;

  display: flex;
  flex-direction: column;
}

.attributes > span {
  padding: 4px 0;

  border-bottom: 1px solid #555;
}
