.button {
  width: 370px;
  height: 50px;

  margin-top: 4px;
  border: 0;
  border-radius: 6px;

  background: linear-gradient(180deg, #eed882 0%, #9f8e5a 100%);

  color: #fff;
  font-weight: 600;
}

.button:hover {
  background: linear-gradient(180deg, #eed882 0%, #e8d17e 100%);
}

.button:disabled {
  background: #777;
}

.button {
  /* Your existing button styles */
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
